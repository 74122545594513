import classNames from "classnames";
import React, { Children } from "react";
import Link from "next/link";
import { useRouter } from "next/router";

export function NavItem(props) {
  const { href, border, children, external } = props;

  const router = useRouter();
  const activeClassName = "bg-black";
  const isActive = router.pathname === href;
  const borderClassName = "border-0 border-t-2 border-gray-600";

  const NavItemWrapper = (_props) => (
    <>
      <div
        className={classNames("absolute left-0 w-1 h-10", {
          [activeClassName]: isActive,
        })}
      ></div>
      <div
        className={classNames({
          [borderClassName]: border,
        })}
      >
        <div className="pl-6 py-2">{_props.children}</div>
      </div>
    </>
  );
  if (!href) return <NavItemWrapper>{children}</NavItemWrapper>;
  if (external)
    return (
      <NavItemWrapper>
        <a className="flex items-center" href={href} target="_blank">
          {children}
        </a>
      </NavItemWrapper>
    );

  return (
    <NavItemWrapper>
      <Link href={href}>
        <a className="flex items-center">{children}</a>
      </Link>
    </NavItemWrapper>
  );
}
