import { FC, ReactElement, useEffect, useLayoutEffect, useState } from "react";
import { useLayout } from "../contexts/LayoutContext";
import cn from "classnames";

export type SidebarProps = {
  id: string;
  render: ReactElement<any, any>;
};

const Sidebar: FC<SidebarProps> = ({ id, render }) => {
  const [startTransition, setStartTransition] = useState(false);
  const [maxWidth, setMaxWidth] = useState<any>(0);
  const { lockScrolling, unlockScrolling, closeSidebar } = useLayout();
  useLayoutEffect(() => {
    lockScrolling();
    return unlockScrolling;
  }, []);

  return (
    <div className="absolute inset-0 flex bg-black bg-opacity-25 pointer-events-auto">
      <button className="flex-grow" onClick={() => closeSidebar(id)}></button>
      <div className={cn("px-8 py-6 bg-white")}>{render}</div>
    </div>
  );
};

export const Sidebars = () => {
  const { sidebars } = useLayout();
  return (
    <>
      {sidebars.map((sidebar) => (
        <Sidebar key={sidebar.id} {...sidebar} />
      ))}
    </>
  );
};
