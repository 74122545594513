/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { GET_EMAIL_TEMPLATES } from "../graphql/queries";
import { useUserContext } from "./UserContext";

const defaultContextValue = {
  emailTemplates: [],
  loading: false,
  error: undefined,
};

export const EmailTemplateContext = createContext(defaultContextValue);

const EmailTemplateContextProvider = (props) => {
  const { children } = props;
  const { isAdmin, called: userInitialized } = useUserContext();
  const [callQuery, { loading, called, data, error }] = useLazyQuery(GET_EMAIL_TEMPLATES);
  const [emailTemplates, setEmailtemplates] = useState([]);

  useEffect(() => {
    if (!userInitialized || !isAdmin) return;
    if (!called) {
      callQuery();
      return;
    }
    if (loading || !data) return;
    setEmailtemplates(data.emailTemplates);
  }, [userInitialized, loading, called]);

  if (!isAdmin)
    return <EmailTemplateContext.Provider value={defaultContextValue}>{children}</EmailTemplateContext.Provider>;

  return (
    <EmailTemplateContext.Provider value={{ emailTemplates, loading, error }}>{children}</EmailTemplateContext.Provider>
  );
};

export default EmailTemplateContextProvider;

export const useEmailTemplateContext = () => {
  const context = useContext(EmailTemplateContext);
  if (!context) throw new Error("EmailTemplateContext must be used with EmailTemplateContextProvider!");
  return context;
};
