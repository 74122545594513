import React, { createContext, FC, useContext, useEffect, useState } from "react";
import { NextRouter } from "next/router";
import { SidebarProps } from "@/components/Sidebars";
import Button from "@/components/form/Button";

const lockScrolling = () => {
  window["__next"]?.classList.add("fixed", "inset-0", "overflow-hidden", "pointer-events-none");
};
const unlockScrolling = () => {
  window["__next"]?.classList.remove("fixed", "inset-0", "overflow-hidden", "pointer-events-none");
};

const openSidebar = (id, render: FC) => {};
const closeSidebar = (id) => {};
const defaultSidebars: SidebarProps[] = [];

const LayoutContext = createContext({
  lockScrolling,
  unlockScrolling,
  openSidebar,
  closeSidebar,
  sidebars: defaultSidebars,
});

export const LayoutContextProvider: FC<{ router: NextRouter }> = ({ children, router }) => {
  const [sidebars, setSidebars] = useState(defaultSidebars);

  const openSidebar = (id, render) => {
    setSidebars([...sidebars, { id, render }]);
  };

  const closeSidebar = (id) => {
    setSidebars(sidebars.filter((sidebar) => sidebar.id !== id));
  };

  return (
    <LayoutContext.Provider value={{ lockScrolling, unlockScrolling, openSidebar, closeSidebar, sidebars }}>
      {children}
    </LayoutContext.Provider>
  );
};

export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (!context) throw new Error("LayoutContext must be used with LayoutContextProvider!");
  return context;
};
